import React, { RefObject } from 'react'

export const Dom = (props: {
  domLocked?: boolean
  iframeRef: RefObject<HTMLIFrameElement>
  currentViewport: { height: number; width: number }
  zoomToFit?: { height?: number; width?: number }
}) => {
  const widthZoom = props.zoomToFit?.width
    ? props.zoomToFit.width / props.currentViewport.width
    : 0
  const heightZoom = props.zoomToFit?.height
    ? props.zoomToFit.height / props.currentViewport.height
    : 0

  return (
    <iframe
      title="Test Replay"
      ref={props.iframeRef}
      data-cy="replay-iframe"
      id="replay"
      sandbox="allow-same-origin allow-scripts"
      className="fs-exclude" // exclude from fullstory
      style={{
        transformOrigin: 'top',
        height: `${props.currentViewport.height}px`,
        width: `${props.currentViewport.width}px`,
        transform: `scale(${Math.min(heightZoom, widthZoom, 1)})`,
        display: 'inherit',
        pointerEvents: props.domLocked ? 'none' : 'initial',
        position: 'absolute',
        backgroundColor: 'white',
      }}
    ></iframe>
  )
}
