import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AttemptOption,
  useAttemptOptions,
} from '../../../utils/useAttemptOptions'
import { Timer, useGlobalTimer } from '../../../utils/useGlobalTimer'
import { getLatestEvent } from '~/src/utils/getLatestEvent'
import type { useReplayData } from '~/src/utils/useReplayData'
import { cypressEvents, appState } from '~/submodules'
import { useDomReplay } from '~/src/utils/useDomReplay'
import type { TestResultStateEnum } from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import type {
  ConsolePropsCommandLog,
  CypressAppEvent,
  TestAttempt,
} from '~/src/webworkers/database.worker'
import { useAttemptModel } from './useAttemptModel'
import type { GhostRange } from '../../ReplayFooter/Scrubber/Scrubber'
import {
  DisplayedSnapshot,
  SnapshotInfo,
  useSnapshotController,
} from './useSnapshotController'
import type { EventsPayload } from '@packages/app-capture-protocol/src/db/schemas/latest'
import type { AutControlsProps } from '../../ReplayBody/Browser/AutControls/AutControls'
import { useDrawerOpenStorage } from '~/src/utils/useDrawerOpenStorage'
import { useHandleConsoleProps } from '../../../utils/useConsoleProps'
import { useUrlContext } from '../UrlContext'
import { getPinInfo } from './usePinInitialCommand'
import type { Snapshot as SnapshotV9 } from '@packages/app-capture-protocol/src/db/schemas/v9'

declare global {
  interface Window {
    __showTestReplayState: boolean
    __testReplayState: ReplayContextValue
    // This is a marker that indicates that we are in discovery mode with respect to the test replay.
    // This flexes some behavior to make the test replay work in the context of discovery.
    IN_DISCOVERY: boolean
    env: 'production' | 'staging'
  }
}

function invalidAttemptError() {
  // We should never see this error, unless the attempt-picker is
  // allowed to select a postponed attempt that is currently null.
  return new Error('TestReplay: invalid test-attempt selected.')
}

function clearReporterSingletonPin() {
  // side-effect to clear pin from
  // submodule state singletons:
  appState.pinnedSnapshotId = null
}

const getRangeFromSnapshotController = (
  displayedSnapshots: ReturnType<
    typeof useSnapshotController
  >['displayedSnapshots']
) => {
  return displayedSnapshots &&
    displayedSnapshots.length >= 2 &&
    displayedSnapshots[0] &&
    displayedSnapshots[displayedSnapshots.length - 1]
    ? {
        startAt: displayedSnapshots[0].timestamp,
        endAt: Number(
          displayedSnapshots[displayedSnapshots.length - 1]?.timestamp
        ),
      }
    : undefined
}

export type PinInfo = {
  id: string
  section: 'command-logs' | 'console-logs' | 'network-calls'
  snapshots: DisplayedSnapshot[]
  hitbox?: EventsPayload['cypress']['log:changed']['coords']
}
type TestId = string
type LogId = string
function setEventHandlers({
  handlePinCommand,
  handleUnpinCommand,
  handleShowSnapshot,
  handleHideSnapshot,
  handleShowCommand,
  handleShowError,
}: {
  handlePinCommand: (args: [TestId, LogId]) => void
  handleUnpinCommand: (args: [TestId, LogId]) => void
  handleShowSnapshot: (args: [TestId, LogId]) => void
  handleHideSnapshot: (args: [TestId, LogId]) => void
  handleShowCommand: (args: [TestId, LogId]) => void
  handleShowError: (args: [TestId, LogId]) => void
}) {
  // IMPORTANT: ensure we don't duplicate listeners:
  cypressEvents.__off()

  cypressEvents.listen({
    // NOTE: review Cypress TestRunner codebase to see all
    // the types of args that can be provided into emit():
    emit(evt: string, ...args: any) {
      // NOTE: event names are defined in
      // the Cypress TestRunner codebase:
      if (evt === 'runner:pin:snapshot') handlePinCommand(args)
      if (evt === 'runner:unpin:snapshot') handleUnpinCommand(args)
      if (evt === 'runner:show:snapshot') handleShowSnapshot(args)
      if (evt === 'runner:hide:snapshot') handleHideSnapshot(args)
      if (evt === 'runner:console:log') handleShowCommand(args)
      if (evt === 'show:error') handleShowError(args)
      return true
    },
    on() {},
  })
}

type AttemptModelInfo = ReturnType<typeof useAttemptModel>

const isSnapshotWithElToHighlightSelectors = (
  snapshot: any
): snapshot is Pick<SnapshotV9, 'elToHighlightSelectors'> =>
  snapshot.elToHighlightSelectors !== undefined

const getElementsToHighlight = (
  renderedSnapshot?: DisplayedSnapshot | null
) => {
  if (!renderedSnapshot) return []

  if (isSnapshotWithElToHighlightSelectors(renderedSnapshot)) {
    return (
      renderedSnapshot.elToHighlightSelectors?.map((v) => ({ selector: v })) ??
      []
    )
  }
  return renderedSnapshot.elementsToHighlight ?? []
}

const hasElementsToHighlight = (snapshot: DisplayedSnapshot) => {
  if (isSnapshotWithElToHighlightSelectors(snapshot)) {
    return (snapshot.elToHighlightSelectors?.length || 0) >= 1
  }
  return (snapshot.elementsToHighlight?.length || 0) >= 1
}

export type ReplayContextValue = {
  testId: string
  test: any // todo
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
  titleParts: string[]
  testState: TestResultStateEnum
  attempts: {
    options: AttemptOption[]
    selectedOption: AttemptOption
    changeAttempt: (
      optIndx: number,
      opts?: {
        play?: boolean
      }
    ) => void
  }
  attemptsModel: AttemptModelInfo['attemptModel']
  testBodyScrollTargetRef: AttemptModelInfo['testBodyScrollTargetRef']
  display: {
    pageLoading: EventsPayload['cypress']['page:loading']
    url: EventsPayload['cypress']['url:changed']
    viewport: EventsPayload['cypress']['viewport:changed']
    autContext?: AutControlsProps | null
  }
  events: {
    aboutBlank: TestAttempt['events']['aboutBlank']
    commands: TestAttempt['events']['commands']
    network: TestAttempt['events']['network']
    consoleLogs: TestAttempt['events']['consoleLogs']
  }
  timer: Timer & {
    max: number
    min: number
    cliffAt?: number
  }
  scrubber: {
    ghostValueHovered?: number
    ghostRangeHovered?: GhostRange
    ghostRangePinned?: GhostRange
    onScrubberChange: (time: number) => void
    setIsScrubbing: (b: boolean) => void
    isScrubbingRef: React.MutableRefObject<boolean>
  }
  devtools: {
    open: boolean
    toggle: VoidFunction
  }
  helpers: {
    pin: SnapshotInfo | null
    setPinned: (info: Pick<PinInfo, 'id' | 'section'>) => void
    clearPinned: VoidFunction
    clearHoveredSnapshots: VoidFunction
    setHoveredSnapshots: (
      info: SnapshotInfo,
      snapshots: DisplayedSnapshot[]
    ) => void
    isDragging: boolean
    setIsDragging: (b: boolean) => void
  }
}

const ReplayContext = React.createContext<ReplayContextValue | null>(null)

export interface ReplayActionsContextValue {
  setTime: ReturnType<typeof useGlobalTimer>['setTime']
  setSpeed: ReturnType<typeof useGlobalTimer>['setSpeed']
  setPinned: ReplayContextValue['helpers']['setPinned']
  clearPinned: ReplayContextValue['helpers']['clearPinned']
  changeAttempt: ReplayContextValue['attempts']['changeAttempt']
}

const ReplayActionsContext =
  React.createContext<ReplayActionsContextValue | null>(null)

export const ReplayContextProvider: React.FC<{
  replayData: NonNullable<ReturnType<typeof useReplayData>['data']>
  testId: string
  initAttempt?: number
  titleParts: string[]
  testState: TestResultStateEnum
  testConfig?: {
    viewportHeight: number
    viewportWidth: number
    testIsolation: boolean
  } | null
  override?: ReplayContextValue // for unit testing
}> = ({
  children,
  override,
  replayData,
  initAttempt,
  testId,
  titleParts,
  testState,
  testConfig,
}) => {
  const { testRoot, testAttempts, attemptOptions } = replayData
  const { attempt, options, setAttempt } = useAttemptOptions(
    attemptOptions,
    initAttempt,
    testAttempts
  )
  const { handleGetConsoleProps } = useHandleConsoleProps()
  const currentAttemptIdx = attempt.value

  const iframeRef = useRef<HTMLIFrameElement>(null)

  // isDragging = is resizing side panels:
  const [isDragging, setIsDragging] = useState(false)

  // Dev tools panel setup
  const {
    isOpen,
    updateIsOpen: toggleDevtools,
    forceOpen: forceDevToolsOpen,
  } = useDrawerOpenStorage('devtools')

  // isScrubbing => is dragging the timestamp Scrubber; we track as a Ref since
  // we do not want this to induce rerenders, we use it for conditionals
  // elsewhere, and the ref ensures that the closure is never stale:
  const isScrubbingRef = useRef(false)
  const setIsScrubbing = (val: boolean) => (isScrubbingRef.current = val)

  const events = testAttempts[currentAttemptIdx]?.events
  if (!events) throw invalidAttemptError()

  const {
    commands,
    dom,
    urls,
    domNetworkAssets,
    domCanvasAssets,
    viewportChanged,
    network,
    consoleLogs,
    pageLoadings,
    aboutBlank,
  } = events
  const cliffAt = testAttempts[currentAttemptIdx]?.cliffAt
  const currMin = testAttempts?.[attempt?.value]?.min ?? 0
  const currMax = testAttempts?.[attempt?.value]?.max ?? 0
  const beginAt = aboutBlank.timeBeforeLast ?? currMax
  const timer = useGlobalTimer({
    testAttempt: currentAttemptIdx,
    min: currMin,
    max: currMax,
    beginAt,
  })
  const { pause, setTime, setSpeed, play } = timer

  const {
    info: pinnedInfo,
    renderedSnapshot: pinnedRenderedSnapshot,
    renderedSnapshotIndex: pinnedRenderedSnapshotIndex,
    displayedSnapshots: pinnedDisplaySnapshots,
    highlightEnabled,
    toggleHighlight,
    clearDisplayedSnapshots: clearPinnedSnapshots,
    setDisplayedSnapshots: setPinnedSnapshots,
    setRenderedSnapshotCounter: setPinnedSnapshotCounter,
  } = useSnapshotController({ isPinned: true })
  useEffect(() => {
    if (pinnedRenderedSnapshot) {
      setTime(pinnedRenderedSnapshot.timestamp)
    }
  }, [setTime, pinnedRenderedSnapshot])

  const {
    info: hoveredInfo,
    renderedSnapshot: hoveredRenderedSnapshot,
    displayedSnapshots: hoveredDisplaySnapshots,
    clearDisplayedSnapshots: clearHoveredSnapshots,
    setDisplayedSnapshots: setHoveredSnapshots,
  } = useSnapshotController({ isPinned: false })

  const ghostValueHovered = React.useMemo(() => {
    const isSameEvent =
      hoveredInfo?.id === pinnedInfo?.id &&
      hoveredInfo?.section === pinnedInfo?.section
    return isSameEvent ? undefined : hoveredRenderedSnapshot?.timestamp
  }, [hoveredInfo, pinnedInfo, hoveredRenderedSnapshot])

  const ghostRangeHovered = React.useMemo(
    () => getRangeFromSnapshotController(hoveredDisplaySnapshots),
    [hoveredDisplaySnapshots]
  )

  const ghostRangePinned = React.useMemo(
    () => getRangeFromSnapshotController(pinnedDisplaySnapshots),
    [pinnedDisplaySnapshots]
  )
  const renderedSnapshot = pinnedRenderedSnapshot ?? hoveredRenderedSnapshot

  function getRenderedTime() {
    if (timer.playing) {
      return timer.time
    }
    if (pinnedRenderedSnapshot) {
      return pinnedRenderedSnapshot.timestamp
    }
    if (renderedSnapshot) {
      return renderedSnapshot.timestamp
    }
    return timer.time
  }
  const renderedTime = getRenderedTime()

  const attemptEvents = React.useMemo(() => {
    return dom.concat(urls)
  }, [dom, urls])

  const highlightElements = React.useMemo(() => {
    return !timer.playing && highlightEnabled
      ? getElementsToHighlight(renderedSnapshot)
      : []
  }, [timer.playing, highlightEnabled, renderedSnapshot])

  const networkResources = React.useMemo(() => {
    return {
      domNetworkAssets,
    }
  }, [domNetworkAssets])

  const canvasResources = React.useMemo(() => {
    return {
      domCanvasAssets,
    }
  }, [domCanvasAssets])

  useDomReplay({
    iframeRef,
    currentTime: renderedTime,
    highlightElements,
    actionHitbox: pinnedInfo ? pinnedInfo?.hitbox : hoveredInfo?.hitbox,
    currentlyPlaying: timer.playing,
    attemptEvents,
    networkResources,
    canvasResources,
  })
  const { setSearchParams, testReplayParams } = useUrlContext()
  const clearPinned = useCallback(() => {
    clearPinnedSnapshots()
    setSearchParams({ pc: undefined })
    clearReporterSingletonPin()
  }, [clearPinnedSnapshots, setSearchParams])
  const setPinned = useCallback(
    (pinInfo: Pick<PinInfo, 'id' | 'section'>) => {
      pause()
      clearPinnedSnapshots()
      clearReporterSingletonPin()
      try {
        const fullPinInfo = getPinInfo(pinInfo, {
          commands,
          consoleLogs,
          network,
          maxTime: currMax,
          minTime: currMin,
          forceDevToolsOpen,
        })
        setPinnedSnapshots(
          {
            id: fullPinInfo.id,
            section: fullPinInfo.section,
            hitbox: fullPinInfo.hitbox,
          },
          fullPinInfo.snapshots
        )
        setSearchParams({ pc: `${pinInfo.id}__${pinInfo.section}` })
      } catch (err) {
        clearPinned()
      }
    },
    [
      pause,
      clearPinnedSnapshots,
      commands,
      consoleLogs,
      network,
      currMax,
      currMin,
      forceDevToolsOpen,
      setPinnedSnapshots,
      setSearchParams,
      clearPinned,
    ]
  )
  useEffect(() => {
    if (testReplayParams.pc) {
      const [id, section] = testReplayParams.pc.split('__') as [
        string,
        PinInfo['section']
      ]
      if (
        id &&
        ['command-logs', 'console-logs', 'network-calls'].includes(section)
      ) {
        setPinned({ id, section })
      }
    }
    // only meant to be called once onMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      // close all ImageBitmaps to release resources
      const imageData = canvasResources.domCanvasAssets?.imageData
      if (imageData) {
        Object.keys(imageData).forEach((key) => {
          imageData[key]?.bitmap.close()
        })
      }
    }
  }, [canvasResources.domCanvasAssets?.imageData])

  const changeAttempt = useCallback(
    (optIdx: number, opts?: { play?: boolean }) => {
      const att = testAttempts[optIdx]
      if (!att) throw invalidAttemptError()

      pause() // must pause before everything!
      const attempt = options[optIdx]
      if (attempt) {
        setAttempt(attempt)
      }
      clearPinned()
      if (opts?.play) {
        play({ beginFrom: att.min })
      } else {
        // ignore if user is just clicking
        // on the already selected option:
        if (currentAttemptIdx === optIdx) return

        setTime(att.events.aboutBlank.timeBeforeLast ?? att.max)
      }
    },
    [
      setAttempt,
      currentAttemptIdx,
      testAttempts,
      options,
      clearPinned,
      setTime,
      pause,
      play,
    ]
  )

  const onScrubberChange = useCallback(
    (t: number) => {
      pause() // must pause before everything!
      setTime(t)
      clearPinned()
    },
    [pause, setTime, clearPinned]
  )

  const { attemptModel: model, testBodyScrollTargetRef } = useAttemptModel({
    commands,
    testRoot,
    testId,
    timer,
    attemptNumber: currentAttemptIdx,
  })

  const value = {
    testId,
    test: testRoot,
    iframeRef,
    titleParts,
    testState,
    attempts: useMemo(
      () => ({
        options,
        changeAttempt,
        selectedOption: attempt,
      }),
      [options, changeAttempt, attempt]
    ),
    attemptsModel: model,
    testBodyScrollTargetRef,
    display: {
      pageLoading: getLatestEvent<EventsPayload['cypress']['page:loading']>(
        pageLoadings,
        renderedTime,
        true
      ),
      url: getLatestEvent<EventsPayload['cypress']['url:changed']>(
        urls,
        renderedTime
      ),
      viewport: getLatestEvent<EventsPayload['cypress']['viewport:changed']>(
        viewportChanged,
        renderedTime,
        {
          width: testConfig?.viewportWidth || 1000,
          height: testConfig?.viewportHeight || 660,
        }
      ),
      autContext: renderedSnapshot
        ? {
            title: pinnedRenderedSnapshot
              ? 'Pinned'
              : renderedSnapshot.name || 'DOM Snapshot',
            snapshots:
              pinnedDisplaySnapshots && pinnedDisplaySnapshots?.length >= 2
                ? (pinnedDisplaySnapshots || []).map((ds, idx) => ({
                    name: ds.name || (idx + 1).toString(),
                    active: pinnedRenderedSnapshotIndex === idx,
                    onClick: () => {
                      setPinnedSnapshotCounter(idx)
                    },
                  }))
                : undefined,
            highlight:
              pinnedRenderedSnapshot &&
              pinnedDisplaySnapshots?.some((s) => hasElementsToHighlight(s))
                ? { active: highlightEnabled, toggle: toggleHighlight }
                : undefined,
            onClose: pinnedRenderedSnapshot ? clearPinned : undefined,
          }
        : undefined,
    },
    // events within the
    // selected attempt:
    events: {
      aboutBlank,
      commands,
      network,
      consoleLogs,
    },
    timer: {
      ...timer,
      min: currMin,
      max: currMax,
      cliffAt,
    },
    scrubber: {
      ghostValueHovered,
      ghostRangeHovered,
      ghostRangePinned,
      onScrubberChange,
      isScrubbingRef,
      setIsScrubbing,
    },
    devtools: {
      open: Boolean(isOpen),
      toggle: toggleDevtools,
    },
    helpers: {
      pin: pinnedInfo,
      setHoveredSnapshots,
      clearHoveredSnapshots,
      setPinned,
      clearPinned,
      isDragging,
      setIsDragging,
    },
  }

  if (window.__showTestReplayState) {
    window.__testReplayState = value
  }

  // useEffect can prevent rerunning setters
  // and give us minor performance boosts:
  useEffect(() => {
    setEventHandlers({
      handlePinCommand([testId, logId]) {
        setPinned({
          id: logId,
          section: 'command-logs',
        })
      },
      handleUnpinCommand([testId, logId]) {
        clearPinned()
      },
      handleShowSnapshot([testId, logId]) {
        // find first command in the stream with given logId:
        const cmd: CypressAppEvent<ConsolePropsCommandLog> | undefined =
          commands
            // @ts-ignore
            .findLast((c) => c.payload.id === logId && c.payload.snapshots)

        if (cmd && cmd.payload) {
          setHoveredSnapshots(
            {
              id: logId,
              section: 'command-logs',
              hitbox: cmd.payload.coords,
            },
            cmd.payload.snapshots ?? []
          )
        }
      },
      handleHideSnapshot([testId, logId]) {
        clearHoveredSnapshots()
      },
      handleShowCommand([testId, logId]) {
        handleGetConsoleProps(currentAttemptIdx + 1, logId)
      },
      handleShowError() {},
    })
  }, [
    clearHoveredSnapshots,
    commands,
    setHoveredSnapshots,
    setPinned,
    clearPinned,
    handleGetConsoleProps,
    currentAttemptIdx,
  ])

  const actions = useMemo(() => {
    return {
      setTime,
      setSpeed,
      setPinned,
      clearPinned,
      changeAttempt,
    }
  }, [setTime, setPinned, setSpeed, clearPinned, changeAttempt])

  return (
    <ReplayContext.Provider value={override ?? value}>
      <ReplayActionsContext.Provider value={actions}>
        {children}
      </ReplayActionsContext.Provider>
    </ReplayContext.Provider>
  )
}

export const useReplayContext = () => {
  const val = useContext(ReplayContext)
  if (val === null) {
    throw new Error(
      `Cannot useReplayContext outside of <ReplayContextProvider />`
    )
  }
  return val
}

export const useReplayActions = () => {
  const val = useContext(ReplayActionsContext)
  if (val === null) {
    throw new Error(
      `Cannot useReplayActions outside of <ReplayActionsContext />`
    )
  }
  return val
}
