import React, { useEffect, useRef } from 'react'
import { ReplayContextProvider } from './components/Context/ReplayContext'
import { ReplayFooter } from './components/ReplayFooter/ReplayFooter'
import { ReplayBody } from './components/ReplayBody/ReplayBody'
import styles from './App.module.scss'
import type { TestResultStateEnum } from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import CypressSpinner from '@cypress-design/react-spinner'
import { DatabaseWorkerProvider } from './components/Context/DatabaseWorkerContext'
import { useReplayData } from './utils/useReplayData'
import ErrorBoundaryDisplay from '../../dashboard/src/lib/error-boundary'
import { ErrorContentOverride } from './components/ErrorBoundary/ErrorContentOverride'
import {
  UrlContextProvider,
  useUrlContext,
} from './components/Context/UrlContext'
import queryString from 'query-string'
import type { NavigateFn } from '@reach/router'
// ping ci

type MainProps = {
  s3BucketUrl: string
  s3BucketAssetsUrl: string | null
  rangeHeader: string | null
  sqlJsUrl: string
  testId: string
  titleParts: string[]
  testState: TestResultStateEnum
  testConfig?: {
    viewportHeight: number
    viewportWidth: number
    testIsolation: boolean
  } | null
  onDBLoaded?: (v: { sizeOfDownload: number }) => void
  hasPaidPlan: boolean
  navigate: NavigateFn
  features: { canViewCanvas: boolean }
}

function useDefaultAttEffect(totalAvailableAttempts: number | undefined) {
  // only onMount: enforce a default att param
  // if non was submitted at the time of mounting:
  const { setSearchParams, testReplayParams } = useUrlContext()

  // only tracked once, onMount:
  const initAttRef = useRef(testReplayParams.att)

  // prevents stale function
  // without rerunning attempts effect:
  const setParamsRef = useRef(setSearchParams)
  useEffect(() => {
    setParamsRef.current = setSearchParams
  })

  useEffect(() => {
    if (totalAvailableAttempts === undefined) {
      return
    }
    const paramOutOfBounds =
      typeof initAttRef.current === 'number' &&
      totalAvailableAttempts < initAttRef.current
    if (paramOutOfBounds || !initAttRef.current) {
      setParamsRef.current({
        att: 1,
      })
      return
    }
  }, [totalAvailableAttempts])
}

const Main: React.FC<MainProps> = ({
  testId,
  s3BucketUrl,
  s3BucketAssetsUrl,
  sqlJsUrl,
  titleParts,
  testState,
  testConfig,
  onDBLoaded,
  rangeHeader,
  features,
}) => {
  const { testReplayParams } = useUrlContext()

  const { data: replayData, error } = useReplayData(
    testId,
    s3BucketUrl,
    sqlJsUrl,
    s3BucketAssetsUrl,
    rangeHeader,
    features
  )

  useDefaultAttEffect(replayData?.attemptOptions?.length)

  useEffect(() => {
    if ((replayData || error) && onDBLoaded) {
      onDBLoaded({ sizeOfDownload: replayData?.sizeOfDownload ?? -1 })
    }

    if (error) {
      // Trigger our exception page

      // check if S3 URL is expired
      const { Expires } = queryString.parse(s3BucketUrl)
      const currentTimeUnixSeconds = Date.now() / 1000
      if (Number(Expires) < currentTimeUnixSeconds) {
        throw new Error('Expired S3 url')
      }

      throw Error(error)
    }
  }, [replayData, error, onDBLoaded, s3BucketUrl])

  if (!replayData) {
    return (
      <div className={styles['test-replay--loading']}>
        <CypressSpinner />
      </div>
    )
  }

  return (
    <ReplayContextProvider
      testId={testId}
      replayData={replayData}
      initAttempt={testReplayParams.att}
      titleParts={titleParts}
      testState={testState}
      testConfig={testConfig}
    >
      <div
        data-cy="test-replay-container"
        className={styles['test-replay-container']}
      >
        <ReplayBody />
        <ReplayFooter />
      </div>
    </ReplayContextProvider>
  )
}

export const App: React.FC<MainProps> = (props) => (
  <ErrorBoundaryDisplay
    fallbackRender={(fallbackProps) => (
      <ErrorContentOverride
        hasPaidPlan={props.hasPaidPlan}
        {...fallbackProps}
      />
    )}
  >
    <UrlContextProvider navigate={props.navigate}>
      <DatabaseWorkerProvider>
        <Main {...props} />
      </DatabaseWorkerProvider>
    </UrlContextProvider>
  </ErrorBoundaryDisplay>
)
