import type { Node as ProtocolNode } from '@packages/app-capture-protocol/src/db/schemas/latest'
import { inDom } from '../in-dom'
import { TEST_REPLAY_CONTROLLED_STYLESHEET_COMMENT } from '../Replay/nodeMap'
import { createNode } from '../createNode'
import { toBase64Asset, updateMetadataWithBase64Asset } from '../toBase64Asset'
import { replayPrefix, safelyApplyAttribute } from '../safelyApplyAttribute'
import { rewriteCss } from '../rewriteCss'
import type { ApplyEventContext } from '../applyEventToReplay'
import type { PendingAdditionQueue } from './PendingAdditionQueue'

export const applyNodeRemoval = ({
  ctx,
  removalPayload,
}: {
  ctx: ApplyEventContext
  removalPayload: {
    id: number
    parentId: number
  }
}) => {
  const parentNode = ctx.nodeMap.current.getNode(removalPayload.parentId)
  const removedNode = ctx.nodeMap.current.getNode(removalPayload.id)
  // Rare case where the node is already removed from the parent node. This
  // happens especially when we're mucking around with style text nodes
  // during replays of test replay.
  if (removedNode && parentNode && parentNode === removedNode.parentNode) {
    // Clean up the node map by removing the node and all its children
    const removedNodes = [removedNode]
    while (removedNodes.length) {
      const currentNode = removedNodes.shift()!
      currentNode.childNodes.forEach((childNode) => {
        removedNodes.push(childNode)
      })
      const id = ctx.nodeMap.current.getId(currentNode as HTMLElement)
      if (id) {
        ctx.nodeMap.current.remove(id)
      }
    }
    parentNode.removeChild(removedNode)
    ctx.nodeMap.current.remove(removalPayload.id)
  }
}

export const applyNodeAddition = ({
  eventId,
  ctx,
  additionPayload,
  pendingAdditionQueue,
}: {
  eventId: number
  ctx: ApplyEventContext
  additionPayload: {
    parentId: number
    node: ProtocolNode
    previousId?: number
  }
  pendingAdditionQueue: PendingAdditionQueue
}) => {
  const parentNode = ctx.nodeMap.current.getNode(
    additionPayload.parentId
  ) as ParentNode

  // Only check the text content of the parent node if it's a style node.
  // adding the === style condition improves performance for the following tickets:
  // https://github.com/cypress-io/cypress-services/issues/7596
  // https://github.com/cypress-io/cypress-services/issues/8179
  // https://github.com/cypress-io/cypress-services/issues/7965
  // https://github.com/cypress-io/cypress-services/issues/7877
  const parentNodeTextContent =
    parentNode?.nodeName === 'STYLE'
      ? parentNode?.textContent?.trim() ?? ''
      : ''

  // If the node is a text node and the parent node is a style node and the text node is empty and this is either a noop or
  // a replay controlled stylesheet, we can skip adding the node. If we don't skip, we may end up inadvertently clearing the
  // styles in the style node. This handles this situation specifically:
  // https://github.com/styled-components/styled-components/blob/22e8b7f233e12500a68be4268b1d79c5d7f2a661/packages/styled-components/src/sheet/Tag.ts#L23-L26
  if (
    additionPayload.node.nodeName === '#text' &&
    parentNode?.nodeName === 'STYLE' &&
    additionPayload.node.nodeValue.trim() === '' &&
    (parentNodeTextContent.includes(
      TEST_REPLAY_CONTROLLED_STYLESHEET_COMMENT
    ) ||
      parentNodeTextContent === '')
  ) {
    return
  }
  const previousNode = ctx.nodeMap.current.getNode(additionPayload.previousId)
  if (parentNode) {
    const parentNodeLocalName = (parentNode as Element).localName
    const createdNode = createNode({
      eventId: eventId ?? 0,
      doc:
        parentNode.nodeName === '#document'
          ? (parentNode as Document)
          : parentNode.ownerDocument || ctx.iframeRef.current!.contentDocument!,
      node: additionPayload.node,
      networkResources: ctx.networkResources,
      nodeMap: ctx.nodeMap.current,
      hrefResolver: ctx.hrefResolver.current,
      parentNodeElementType: parentNodeLocalName,
      // ownerSVGElement is undefined if the child is not in an SVG tree and null if it's the SVG itself
      isChildOfSvg: (parentNode as SVGElement).ownerSVGElement !== undefined,
      config: ctx.config,
      base64AssetMetadata: ctx.base64AssetMetadata,
      hasCapturedAndProcessedCanvasAssets:
        !!ctx.canvasResources?.domCanvasAssets,
    })
    if (!createdNode) {
      return
    }

    const doInsert = () => {
      // This is an extra precaution to ensure that the DOM is ready for the addition
      // If it's not, mark the insertion as unsuccessful and we'll add it to the pending queue and try again later
      if (!parentNode || (previousNode && !parentNode.contains(previousNode))) {
        return false
      }

      // TODO: Cleanup how we handle replacing the content in a document.
      // If the parentNode is a document and we're creating an html element
      // we need to ensure that the previous one does not exist.
      if (parentNode.nodeName === '#document') {
        const childHTMLNode = (parentNode as Document).documentElement
        const docTypeNode = (parentNode as Document).doctype
        if (
          childHTMLNode &&
          (createdNode as DocumentType).nodeType === Node.ELEMENT_NODE
        ) {
          parentNode.replaceChild(createdNode, childHTMLNode)
        } else if (
          (createdNode as DocumentType).nodeType === Node.DOCUMENT_TYPE_NODE
        ) {
          if (docTypeNode) {
            docTypeNode.replaceWith(createdNode)
          } else {
            parentNode.prepend(createdNode)
          }
        } else {
          parentNode.append(createdNode)
        }
      } else if (previousNode) {
        parentNode.insertBefore(createdNode, previousNode.nextSibling)
      } else {
        parentNode.prepend(createdNode)
      }

      return true
    }

    if (inDom(parentNode)) {
      pendingAdditionQueue.add(doInsert)
    } else {
      doInsert()
    }
  }
}

export const applyAttributeModification = ({
  eventId,
  ctx,
  attributeModificationPayload,
}: {
  eventId: number
  ctx: ApplyEventContext
  attributeModificationPayload: {
    id: number
    name: string
    value: string
  }
}) => {
  const modifiedNode = ctx.nodeMap.current.getNode(
    attributeModificationPayload.id
  )
  if (modifiedNode) {
    const doc =
      modifiedNode.ownerDocument! ?? ctx.iframeRef.current?.contentDocument
    safelyApplyAttribute(
      modifiedNode as HTMLElement,
      attributeModificationPayload.name,
      attributeModificationPayload.name === 'style'
        ? rewriteCss(
            doc,
            ctx.networkResources,
            attributeModificationPayload.value,
            ctx.hrefResolver.current,
            '',
            // Cache key set uses event id to ensure
            // this event always resolves to the same resulting css
            `${eventId}_${attributeModificationPayload.id}`,
            true,
            ctx.base64AssetMetadata
          )
        : attributeModificationPayload.value
    )
    // If we've updated the href on the style/link tag or we've made the tag  "stylesheet"
    // (rather than "preload"), we will need to re-resolve the contents
    if (
      ['LINK', 'STYLE'].includes(modifiedNode.nodeName.toUpperCase()) &&
      (attributeModificationPayload.name === 'href' ||
        (attributeModificationPayload.name === 'rel' &&
          attributeModificationPayload.value === 'stylesheet'))
    ) {
      let newUrl
      if (attributeModificationPayload.name === 'href') {
        newUrl = attributeModificationPayload.value
      } else {
        const modifiedNodeAsElement = modifiedNode as HTMLElement
        newUrl = modifiedNodeAsElement.getAttribute(replayPrefix('href'))
      }
      if (newUrl) {
        const resolvedUrl = ctx.hrefResolver.current.resolve(
          newUrl,
          modifiedNode.ownerDocument
        )
        const possibleHash =
          ctx.networkResources.domNetworkAssets.pathHash?.[resolvedUrl] ||
          ctx.networkResources.domNetworkAssets.pathHash?.[
            attributeModificationPayload.value
          ] ||
          ctx.networkResources.domNetworkAssets.pathHash?.[
            `/${attributeModificationPayload.value}`
          ]

        if (possibleHash) {
          const createdNode = doc.createElement('style')

          // copy over the attributes from the original node
          if (modifiedNode.nodeName.toUpperCase() === 'LINK') {
            ;(modifiedNode as HTMLElement)
              .getAttributeNames()
              .forEach((attr) => {
                if (attr !== 'href') {
                  safelyApplyAttribute(
                    createdNode,
                    attr,
                    (modifiedNode as HTMLElement).getAttribute(attr) ?? ''
                  )
                }
              })
          }

          safelyApplyAttribute(createdNode, 'rel', 'stylesheet')
          safelyApplyAttribute(createdNode, 'type', 'text/css')
          safelyApplyAttribute(
            createdNode,
            'href',
            attributeModificationPayload.value
          )

          createdNode.appendChild(
            doc.createTextNode(
              rewriteCss(
                doc,
                ctx.networkResources,
                ctx.networkResources.domNetworkAssets.hashData[possibleHash]
                  ?.body ?? '',
                ctx.hrefResolver.current,
                resolvedUrl,
                possibleHash,
                false,
                ctx.base64AssetMetadata
              )
            )
          )

          // replace the modified node with the created node
          modifiedNode.parentNode?.replaceChild(createdNode, modifiedNode)
        }
      }
    } else if (
      attributeModificationPayload.name === 'src' &&
      modifiedNode.nodeName.toUpperCase() === 'IMG'
    ) {
      const possibleHash =
        ctx.networkResources.domNetworkAssets.pathHash?.[
          attributeModificationPayload.value
        ] ||
        ctx.networkResources.domNetworkAssets.pathHash?.[
          ctx.hrefResolver.current.resolve(
            attributeModificationPayload.value,
            modifiedNode.ownerDocument
          )
        ]
      if (possibleHash) {
        const base64Asset = toBase64Asset(
          ctx.networkResources.domNetworkAssets.hashData[possibleHash]
        )

        if (ctx.base64AssetMetadata) {
          updateMetadataWithBase64Asset(ctx.base64AssetMetadata, base64Asset)
        }

        safelyApplyAttribute(
          modifiedNode as HTMLImageElement,
          attributeModificationPayload.name,
          base64Asset
        )
      }
    }

    // If we're modifying the width or height of a canvas or video element, we need to update the style attribute to match
    // The style attribute will take precedence over the width and height attributes in unsupported content like video and canvas
    if (['CANVAS', 'VIDEO'].includes(modifiedNode.nodeName.toUpperCase())) {
      if (attributeModificationPayload.name === 'width') {
        ;(
          modifiedNode as HTMLElement
        ).style.width = `${attributeModificationPayload.value}px`
      }
      if (attributeModificationPayload.name === 'height') {
        ;(
          modifiedNode as HTMLElement
        ).style.height = `${attributeModificationPayload.value}px`
      }
    }
  }
}

export const applyAttributeRemoval = ({
  ctx,
  attributeRemovalPayload,
}: {
  ctx: ApplyEventContext
  attributeRemovalPayload: {
    id: number
    name: string
  }
}) => {
  const nodeToRemove = ctx.nodeMap.current.getNode(attributeRemovalPayload.id)
  if (nodeToRemove) {
    ;(nodeToRemove as Element).removeAttribute(attributeRemovalPayload.name)
  }
}

export const applyCharacterDataModification = ({
  ctx,
  characterDataPayload,
}: {
  ctx: ApplyEventContext
  characterDataPayload: {
    id: number
    characterData: string
  }
}) => {
  const nodeToUpdate = ctx.nodeMap.current.getNode(characterDataPayload.id)
  if (nodeToUpdate) {
    nodeToUpdate.textContent = characterDataPayload.characterData
  }
}
